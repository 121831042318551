import _core2 from "@styled-system/core";
var exports = {};
exports.__esModule = true;
exports["default"] = exports.color = void 0;
var _core = _core2;
var config = {
  color: {
    property: "color",
    scale: "colors"
  },
  backgroundColor: {
    property: "backgroundColor",
    scale: "colors"
  },
  opacity: true
};
config.bg = config.backgroundColor;
var color = (0, _core.system)(config);
exports.color = color;
var _default = color;
exports["default"] = _default;
export default exports;
export const __esModule = exports.__esModule;
const _color = exports.color;
export { _color as color };